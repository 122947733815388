import React, { Fragment, useState, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { roles } from "../../constants/constant";
import MapLayers from "../map/MapLayers";
import ControlMapCenter from "../map/ControlMapCenter";
import MeasurementControl from "../map/MeasurementControl";
import FullScreenControl from "../map/FullScreenControl";
import RenderGeometryLine from "./RenderGeometryLine";
import RenderGeometryLabelLine from "./RenderGeometryLabelLine";
import RenderGeometryGridLine from "./RenderGeometryGridLine";
import RenderGeometryLabel from "./RenderGeometryLabel";
import EchartDrawControl from "./EchartDrawControl";
import RenderGeometryPoint from "./RenderGeometryPoint";

const ElecronicChartMap = ({
  selctedAirport,
  selectedEchart,
  airportLocation,
  zoomLevel,
  eChartData,
  isShowLabel,
  isShowTable,
  selectedExportType,
  handleLabelClickOnMap,
  handleChartGridExport,
}) => {
  const user = useSelector((state) => state.userSession.user);
  const [editableLayer, setEditableLayer] = useState(null);
  const isAdmin = user?.role === roles.admin;

  const handleEditShap = (data) => {
    setEditableLayer(data);
  };

  const handleDrawControlCallback = () => {
    setEditableLayer(null);
  };

  const handleLabelClick = (label) => {
    handleLabelClickOnMap(label);
  };

  const eChartMapE = useMemo(() => {
    return (
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        <RenderGeometryLine
          data={eChartData}
          selectedEchart={selectedEchart}
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
          isShowTable={isShowTable}
          handleEditShap={handleEditShap}
        />
        <RenderGeometryPoint
          data={eChartData}
          selectedEchart={selectedEchart}
        />
        {isShowLabel && selectedEchart && (
          <RenderGeometryLabelLine data={eChartData[selectedEchart]} />
        )}
        <RenderGeometryLabel
          data={selectedEchart ? eChartData[selectedEchart] : null}
          isShowLabel={isShowLabel}
          handleLabelClick={handleLabelClick}
        />
        {selectedExportType === "grids" && (
          <RenderGeometryGridLine
            data={eChartData.grids}
            handleChartGridExport={handleChartGridExport}
            zoomLevel={zoomLevel}
          />
        )}
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        {/* <PrintMap
          pageTitle={selctedAirport ? `${selctedAirport.name} (${selctedAirport.location_indicator})` : undefined}
        /> */}
        <FullScreenControl />
        <MeasurementControl />
        <ZoomControl position="bottomright" />
        {isAdmin && (
          <EchartDrawControl
            selctedAirport={selctedAirport}
            editableLayer={editableLayer}
            handleCallback={handleDrawControlCallback}
          />
        )}
        <MapLayers />
        <ScaleControl position="topleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airportLocation,
    zoomLevel,
    selctedAirport,
    eChartData,
    isShowLabel,
    selectedEchart,
    isShowTable,
    editableLayer,
    selectedExportType,
  ]);

  return <Fragment>{eChartMapE}</Fragment>;
};

export default memo(ElecronicChartMap);
