import React, { useState, useRef, memo } from "react";
import { useSelector } from "react-redux";
import { Polyline, Popup, FeatureGroup } from "react-leaflet";
import { chunk } from "lodash";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { areaHighLightColor } from "../../constants/colors";
import { layerWiseColor } from "../../constants/eChartData";
import { roles } from "../../constants/constant";
import PopupBox from "../map/PopupBox";
import { LightButton } from "../button";

const RenderGeometryLine = ({
  data,
  selectedEchart,
  airportLocation,
  zoomLevel,
  isShowTable,
  handleEditShap,
}) => {
  // const map = useMap();
  const lineRef = useRef(null);
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const isAdmin = user?.role === roles.admin;
  const charData = data ? data[selectedEchart] : null;
  const geoData =
    charData && charData.GeometryLine_List ? charData.GeometryLine_List : [];
  const geometryLineChunk = chunk(geoData, 10);

  // useEffect(() => {
  //   if (!selectedEchart) {
  //     map.setView(airportLocation, zoomLevel, { animate: false });
  //     map.fitBounds(map.getBounds());
  //     return;
  //   }

  //   const flattenCoordinates = flattenDeep(
  //     geoData.flatMap((x) => x.geometry_json.coordinates)
  //   );
  //   let lats = flattenCoordinates
  //     .map((c) => c.lat)
  //     .reduce((previous, current) => (current += previous), 0.0);
  //   let longs = flattenCoordinates
  //     .map((c) => c.lng)
  //     .reduce((previous, current) => (current += previous), 0.0);

  //   const position = [
  //     lats / flattenCoordinates.length,
  //     longs / flattenCoordinates.length,
  //   ];
  //   map.setView(position, zoomLevel, { animate: false });
  //   map.fitBounds(lineRef.current.getBounds());
  // }, [selectedEchart, isShowTable]); // eslint-disable-line

  const handlePolylineOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolylineOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonLine = (data) => {
    handleEditShap(data);
  };

  return (
    <FeatureGroup ref={lineRef}>
      {geometryLineChunk.map((geometryLines) => {
        return geometryLines.map((geometryLine) => {
          const coordinates = geometryLine.geometry_json.coordinates;

          return (
            geometryLine.geometry_json.type === "MultiLineString" &&
            coordinates.map((coord, i) => {
              return (
                <Polyline
                  key={`${geometryLine.id}_${i}`}
                  positions={coord.map((point) => [point.lat, point.lng])}
                  pathOptions={{
                    color:
                      selectedOverE?.id === geometryLine.id
                        ? areaHighLightColor
                        : layerWiseColor[geometryLine.layer] || "#000000",
                    weight: selectedOverE?.id === geometryLine.id ? 3 : 1.2,
                    opacity: selectedOverE?.id === geometryLine.id ? 1 : 0.8,
                  }}
                  eventHandlers={{
                    mouseover: () => handlePolylineOver(geometryLine),
                    mouseout: () => handlePolylineOut(),
                  }}
                >
                  <Popup>
                    <PopupBox>
                      <Box>ID:</Box>
                      <Box>{geometryLine.id}</Box>
                      <Box>Layer:</Box>
                      <Box>{geometryLine.layer}</Box>
                    </PopupBox>
                    {isAdmin && (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <LightButton
                          label="Modify"
                          onClick={() => handlePolygonLine(geometryLine)}
                        />
                      </Stack>
                    )}
                  </Popup>
                </Polyline>
              );
            })
          );
        });
      })}
    </FeatureGroup>
  );
};

export default memo(RenderGeometryLine);
