import React, { useState, useRef, memo } from "react";

import { FeatureGroup, CircleMarker } from "react-leaflet";
import { chunk } from "lodash";
import { areaHighLightColor } from "../../constants/colors";

const RenderGeometryPoint = ({ data, selectedEchart }) => {
  const lineRef = useRef(null);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const charData = data ? data[selectedEchart] : null;

  const pointData =
    charData && charData.GeometryLine_List
      ? charData.GeometryLine_List.filter(
          (d) => d.geometry_json.type === "Point"
        )
      : [];

  const geoPointDataChunk = chunk(pointData, 10);

  const handlePolylineOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolylineOut = () => {
    setSelectedOverE(null);
  };

  return (
    <FeatureGroup ref={lineRef}>
      {geoPointDataChunk.map((geometryPoints) => {
        return geometryPoints.map((geometryPoint) => {
          return (
            geometryPoint.geometry_json.type === "Point" && (
              <CircleMarker
                key={geometryPoint.id}
                pane="tooltipPane"
                center={geometryPoint.geometry_json.coordinates}
                pathOptions={{
                  color:
                    selectedOverE?.id === geometryPoint.id
                      ? areaHighLightColor
                      : "#000000",
                  fillOpacity: 0.5,
                  weight: 1,
                }}
                radius={3}
                eventHandlers={{
                  mouseover: () => handlePolylineOver(geometryPoint),
                  mouseout: () => handlePolylineOut(),
                }}
              />
            )
          );
        });
      })}
    </FeatureGroup>
  );
};

export default memo(RenderGeometryPoint);
