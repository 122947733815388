import React from "react";
import { PrimaryButton } from "./button";

export default function EChartExportButton({
  label,
  selectedEchart,
  isLoading,
  handleExport,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleExport(selectedEchart, "full");
  };

  const handleNonADCClick = () => {
    handleExport(selectedEchart, "full");
  };

  return (
    <>
      {selectedEchart !== "ADC" && (
        <PrimaryButton
          label={label}
          isLoading={isLoading}
          disabled={isLoading || !selectedEchart || selectedEchart === "ATOCA"}
          onClick={handleNonADCClick}
        />
      )}
      {selectedEchart === "ADC" && (
        <PrimaryButton
          label={label}
          id="export-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          isLoading={isLoading}
          disabled={isLoading || !selectedEchart}
          onClick={handleClick}
        />
      )}
    </>
  );
}
